@import '~antd/dist/antd.less';
@font-face {
  font-family: 'Avenir-Roman';
  src: local('Avenir-Roman'),
    url('./assets/fonts/Avenir-Roman.woff') format('woff');
}
@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy800.ttf') format('truetype');
}
@mobile: ~'only screen and (max-width: 700px)';
body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  // font-family: "Avenir-Roman";
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
}
.ant-btn {
  border-radius: 4px;
}
.ant-modal-content {
  border-radius: 8px;
}
.ant-select {
  border-radius: 4px;
}
.ant-input {
  border-radius: 4px;
}
.ant-modal {
  border-radius: 8px;
}
.ant-form-item-label {
  color: #363e3d;
}
.ant-tag-checkable-checked {
  background: #f0f8f6;
  color: black;
  border: 1px solid #278977 !important;
  border-radius: 4px;
}
.ant-collapse-item.site-collapse-custom-panel {
  background: #fbfbfb;
  margin: 1rem;
  padding: 0.5rem;
  border-radius: 8px;
  border: none;
}
.ant-collapse.ant-collapse-borderless.ant-collapse-icon-position-left.site-collapse-custom-collapse {
  background: white;
}
.ant-select.ant-pagination-options-size-changer.ant-select-single.ant-select-show-arrow {
  display: none;
}
.category-card {
  margin-right: 1rem;
  border-radius: 8px;
  background-color: #f5f5f5;
  border: none;
  cursor: pointer;
}
.category-card:hover {
  background-color: #f0f8f6;
  // filter: drop-shadow(10px 10px 5px #F3F3F3);
  .category-card-icon {
    color: #fff;
    background-color: #0b7764;
    border-radius: 50%;
  }
}

.dualCols {
  min-height: 100px !important;
}

.product-card {
  // margin-right: 2rem;
  margin-top: 1rem;
  border-radius: 8px;
  min-height: 250px;
  object-fit: cover;
  // border:none;
  border: 1px solid #e6e7e7;
  img {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border: 1px solid #e6e7e7;
    border-bottom: 0px;
    align-items: center;
  }

  .productDetail {
    cursor: pointer;
  }

  .product-id {
    margin-bottom: 0.2rem;
    font-size: 16px;
    width: 170px;

    &.gridSmallText {
      font-size: 14px;
    }
  }
  .product-price {
    font-size: 1.2rem;
    margin-bottom: 0.2rem;
  }
  .product-name {
    font-weight: bolder;
    margin-bottom: 0.2rem;
  }
  .product-colors {
    margin-bottom: 0.2rem;
  }
  .product-dimensions {
    margin-bottom: 0.2rem;
  }
  @media @mobile {
    margin-right: 0px;
  }
}
.filters {
  @media @mobile {
    display: none;
  }
}
.search {
  @media @mobile {
    display: none;
  }
}
.sort {
  @media @mobile {
    display: none;
  }
}
.editable-cell {
  position: relative;
}
.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
  min-height: 30px;
}
.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
  border-radius: 4px;
}
.product-cart-image {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.buyer-header {
  @media @mobile {
    display: none;
  }
}
.cta {
  width: 184px;
  background-color: #1d2624 !important;
  border: 0;
  color: #fff;
  height: 48px !important;
  @media @mobile {
    width: 100%;
    height: 48px;
    font-size: 16px;
  }
  &:hover {
    background-color: #040e0c;
    color: #fff;
  }
}
.buyer-home-cta {
  width: 100%;
  background-color: #1d2624 !important;
  border: 0;
  color: #fff !important;
  height: 48px;
  @media @mobile {
    width: 100%;
    height: 48px;
    font-size: 16px;
  }
}
.buyer-home-title {
  font-size: 2rem;
  @media @mobile {
    font-size: 1.5rem !important;
    margin-top: 1rem !important;
  }
}
.filter-panel {
  background-color: white;
}
.ant-upload.img {
  width: 15rem !important;
  height: 15rem !important;
}
.ant-upload {
  width: 15rem !important;
  height: 15rem !important;
}
.ant-upload-list-picture-card-container {
  width: 15rem !important;
  height: 15rem !important;
}
.bulk-edit-card {
  width: 625px;
  padding: 1.3rem;
  margin: 0.5rem;
  border-radius: 8px;
  box-shadow: -5px 5px 10px 0px rgba(0, 0, 0, 0.21);
  position: absolute;
  z-index: 100;
  overflow: auto;
  background: rgb(255, 255, 255);
}
.product-modal {
  width: 90%;
  @media @mobile {
    width: 100%;
  }
}
.product-images {
  width: 90%;
  @media @mobile {
    width: 100%;
  }
}
.icons {
  @media @mobile {
    display: none;
  }
}
.carousel.carousel-slider {
  height: 100% !important;
}
.overflow-y-scroll::-webkit-scrollbar {
  display: none;
}
.overflow-y-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.slider {
  height: 100% !important;
}
.slider-wrapper {
  height: 100%;
}
.product-card-slider-view {
  margin-top: 1rem;
  min-height: 250px;
  cursor: pointer;
  // border:none;
  // border: 1px solid red;
  border-radius: 8px;
  background: linear-gradient(
    115.1deg,
    rgba(230, 244, 190, 0.348) -2.16%,
    rgba(201, 228, 223, 0.384) 85.09%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title {
    font-weight: 900;
    font-size: 18px;
    margin: 1.5em 0em;
    @media @mobile {
      margin: 0.5em 0em;
    }
  }
}

@media only screen and (max-device-width: 600px) {
  .sliderViewCarousel {
    height: calc(100vh - 180px) !important;
  }
}

//product details
//fonts
@xlarge: 20px;
@large: 18px;
@medium: 16px;
@small: 14px;
@xsmall: 12px;

@bold: 700;
@regular: 400;

.t1 {
  font-size: @large;
  font-weight: @bold;
}

.t2 {
  font-size: @large;
  font-weight: @regular;
}

.t3 {
  font-size: @medium;
  font-weight: @bold;
}

.t4 {
  font-size: @medium;
  font-weight: @regular;
}

.t5 {
  font-size: @small;
  font-weight: @bold;
}

.t6 {
  font-size: @small;
  font-weight: @regular;
}

.t7 {
  font-size: @xlarge;
  font-weight: @bold;
}

.t8 {
  font-size: @xsmall;
  font-weight: @regular;
}

//product details
.cursor {
  cursor: pointer;
}
.scroll {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
}
.sticky-container {
  background: white;
  z-index: 10;
  border-block-end: '1px solid #E6E7E7';
  border-radius: '0.2em';
}

.header {
  padding: 1rem 3.5rem;

  .img {
    cursor: pointer;
    display: inline;
    padding: 0 0rem 0.2rem 0;
    margin-right: 0.4rem;
    margin-top: 0.15rem;
  }
}
.go-back {
  display: inline;
  padding: 0 0.5rem 0.25rem 0;
  cursor: pointer;
}

.breadcrumb {
  padding: 1rem 4rem;
}
.carousel.carousel-slider button {
  display: flex;
  z-index: 2;
}

.wishlist-btn {
  white-space: nowrap;
  width: 98%;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 3rem;
}

.image-container {
  padding: 1rem 4rem;
}

.price {
  font-size: 1.5rem;
  padding: 0.5rem 0;
}

.wishlist {
  display: inline;
  padding: 0 0.4rem 0.25rem 0;
}

.chat {
  margin-top: 1rem;
  padding: 0.75rem;
  border-radius: 5px;
}

.details-container {
  margin: 1rem 0;
  overflow-y: auto;
  overflow-x: hidden;

  &.desktop {
    max-height: 445px;
  }

  &.mobileView {
    overflow-y: hidden;
    // max-height: 100%;
    height: auto;
    margin-top: 0px;
  }
}

.details {
  padding: 0.5rem 0;
}

.scroll-btn {
  cursor: pointer;
  height: 4rem;
  margin-top: 1rem;
  border-radius: 2rem;
}
.similar-products {
  margin-top: 1rem;
  padding: 1rem 4rem;
}

.line {
  display: none;
}

.similar-prod-cards {
  margin: 0 3rem;

  .cards {
    margin: 1rem;
    cursor: pointer;
  }
}

.explore-products {
  margin: 1rem 0 6rem 0;

  &.mobileView {
    margin-bottom: 12rem;
  }
}

//wishlist page
.sticky-container2 {
  background: white;
  z-index: 10;
  border-block-end: '1px solid #E6E7E7';
  border-radius: '0.2em';
}
.header-wishlist-page {
  padding: 0.5rem 3.5rem;
}
.divider {
  margin: 0;
}

.wishlist-download {
  padding: 1rem 4rem;
}
.checked-products {
  padding: 1rem 4rem;
}
.quote-card {
  margin-top: 1rem;
  padding: 2rem 2rem 0 2rem;
  border-radius: 8px;
  background: #f5f5f5;

  &.quoteMobile {
    margin-bottom: 5rem !important;
  }
}

.modal-btns {
  display: flex;
  align-items: center;
  &.mobileBtn {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background: white;
  z-index: 10;
  padding: 1rem;
  box-shadow: 0 -5px 24px #e8e8e8;
  }
}

@media only screen and (max-width: 480px) {
  .header {
    padding: 1rem;
  }
  .breadcrumb {
    display: none;
  }
  .image-container {
    padding: 0 2rem;
  }
  .id-bar {
    margin-top: 0.25rem;
    padding: 0.25rem 0;
  }
  .modal-btns {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background: white;
    z-index: 10;
    padding: 1rem;
    box-shadow: 0 0 10px #cdcfce;
  }
  .details-text {
    display: none;
  }
  .line {
    display: block;
  }

  //query page
  .sticky-container2 {
    display: none;
  }
  .divider {
    display: none;
  }
  .wishlist-download {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: white;
    z-index: 10;
    padding: 1rem;
  }
  .download-text {
    display: none;
  }
  .checked-products {
    padding: 1rem;
    margin-top: 3rem;
  }
  .wishlist-svgs {
    display: none;
  }
  .quote-text {
    margin-bottom: 2.5rem;
  }
  .quote-card {
    padding: 2rem;
    padding-bottom: 0rem;
    margin-top: 0;
    margin-bottom: 4rem;
    border-radius: 8px;
    background: linear-gradient(95.11deg, #f0f8f6 0.56%, #f0f2e5 108.49%);
  }
  .remove {
    display: none;
  }
  .get-quote-all {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .header {
    padding: 1rem 2rem;
  }
  .breadcrumb {
    display: none;
  }
  .image-container {
    padding: 0 2rem;
  }
  .id-bar {
    margin-top: 0.25rem;
    padding: 0.25rem 0;
  }
  .modal-btns {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background: white;
    z-index: 10;
    padding: 1rem;
    box-shadow: 0 -5px 24px #e8e8e8;
  }
  .similar-products {
    margin-top: 0;
    padding: 1rem 2rem;
  }
  .line {
    display: block;
  }
  .similar-prod-cards {
    margin: 0 2rem;
  }
  //query page
  .sticky-container2 {
    display: none;
  }
  .divider {
    display: none;
  }
  .wishlist-download {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: white;
    z-index: 10;
    padding: 1rem;
  }
  .download-text {
    display: none;
  }
  .checked-products {
    padding: 1rem;
    margin-top: 3rem;
  }
  .wishlist-svgs {
    display: none;
  }
  .get-quote-all {
    display: none;
  }
}

.ant-image-preview-img-wrapper {
  display: flex;
  max-height: 100vh;
  justify-content: center;
  align-items: center;
}
.pillsTitle{
  color:#1D2624;
}
.selectedCataloguePills{
  border-radius: 30px;
  background-color: #F5F5F5;
  color:#1D2624;
  padding:0.2em 1em;
  border: 1px solid #4F5655;

  &.ant-btn:focus {
    background-color: #F5F5F5;
    color:#1D2624;
    border-color: #4F5655;
  }
}
.cataloguePills{
  color:#4F5655;
  border-radius: 30px;
  background-color: #ffffff;
  padding:0.2em 1em;
  border: 1px solid #dadbda;
}
// .shortlistedAtttributeContainer{
  // height: 7vh;
  // overflow: scroll;
// }
.shortlistedAtttribute{
  width: 100%;
  overflow-y: scroll;
  overflow-x: scroll;
}

.variantPill{
  border-radius: 30px;
  color:#1D2624;
  padding:0.2em 1em;
  border: 1px solid #4F5655;
  margin: 0.2em 0.5em;
}
@primary-color: #278977;